/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../generic/Image';
import { heroShape } from './shapes';
import Paragraphs from '../generic/Paragraphs';

if (process.env.CLIENT) {
  require('./Hero.scss'); // eslint-disable-line global-require
}

const Hero = ({
  chef,
  className,
  footer,
  imageId,
  links,
  occasionSubtitle,
  subtitle,
  synopsis,
  title,
  type,
  url,
}) => (
  <div className={classNames('gel-wrap promo-hero', className)}>
    <div className="gel-layout gel-layout--flush">
      <div className="gel-layout__item gel-2/3@l">
        <a href={url}>
          <Image src={imageId} />
        </a>
      </div>
      <div
        className={classNames(
          'gel-layout__item',
          'gel-1/3@l',
          'promo-hero__text-section',
          type && 'padding-bottom'
        )}
      >
        <div className="gel-wrap">
          <div className="gel-layout">
            <div className="gel-layout__item">
              <div className="promo-hero__title">
                <a className="gel-trafalgar-bold promo-hero__link" href={url}>
                  {title}
                </a>
              </div>
              {chef && <span className="gel-great-primer promo-hero__chef">{`by ${chef}`}</span>}
              {occasionSubtitle && (
                <h3 className="gel-great-primer promo-hero__subtitle">{occasionSubtitle}</h3>
              )}
              {synopsis && <Paragraphs text={synopsis} className="gel-pica promo-hero__synopsis" />}
              {subtitle && <h3 className="gel-great-primer promo-hero__subtitle">{subtitle}</h3>}
              {links && (
                <ul className="promo-hero__sublinks">
                  {links.map(({ href, text }) => (
                    <li key={href} className="promo-hero__sublinks-item">
                      <a className="gel-pica promo-hero__link" href={href}>
                        {text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              {footer && (
                <div className="promo-hero__footer">
                  <span className="gel-pica-bold">
                    {'See '}
                    <a className="promo-hero__link" href={footer.href}>
                      {footer.text}
                    </a>
                  </span>
                  {` (${footer.totalCount})`}
                </div>
              )}
            </div>
          </div>
        </div>
        {type && <span className="gel-long-primer promo-hero__type">{type}</span>}
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  ...heroShape,
  className: PropTypes.string,
};

Hero.defaultProps = {
  className: undefined,
};

export default Hero;
