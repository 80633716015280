import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Hero from '../promo/Hero';
import { CollectionDescription, CollectionTitle } from '../promo/Collection';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import { heroShape, promoShape } from '../promo/shapes';
import ContentSeparator from '../generic/ContentSeparator';
import MetaProperties from '../generic/MetaProperties';

export const Page = ({
  description,
  hero,
  id,
  imageUrl,
  occasionId,
  occasionName,
  recipes,
  relatedCollections,
  title,
}) => {
  const shouldShowRelatedCollections = relatedCollections.length > 0 && occasionId && occasionName;
  return (
    <>
      <div className="collection-page-wrapper">
        <MetaProperties title={title} description={description} imageUrl={imageUrl} />

        {hero && (
          <>
            <div className="gel-wrap page-header">
              <CollectionTitle TitleElement="h1">{title}</CollectionTitle>
              <CollectionDescription description={description} />
            </div>
            <Hero {...hero} />
          </>
        )}

        <StandardCollection12Promos
          id={`collection-${id}-recipes`}
          {...(hero ? {} : { title, description })}
          promos={recipes}
          maxCollectionSize={recipes.length}
          TitleElement="h1"
        />
        <ContentSeparator />
      </div>
      {shouldShowRelatedCollections && (
        <div className="food-body__grey-background">
          <StandardCollection12Promos
            id={`collection-${occasionId}-recipes`}
            title={`More ${occasionName} collections`}
            promos={relatedCollections}
            maxCollectionSize={relatedCollections.length}
          />
          <ContentSeparator />
        </div>
      )}
    </>
  );
};

Page.defaultProps = {
  hero: undefined,
  occasionId: undefined,
  occasionName: undefined,
  recipes: [],
  relatedCollections: [],
};

Page.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  hero: PropTypes.shape(heroShape),
  occasionId: PropTypes.string,
  occasionName: PropTypes.string,
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  relatedCollections: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string.isRequired,
};

export default connect(({ collectionReducer }) => ({
  description: collectionReducer.description,
  hero: collectionReducer.hero,
  id: collectionReducer.id,
  imageUrl: collectionReducer.imageUrl,
  occasionId: collectionReducer.occasionId,
  occasionName: collectionReducer.occasionName,
  recipes: collectionReducer.recipes,
  relatedCollections: collectionReducer.relatedCollections,
  title: collectionReducer.title,
}))(Page);
